<template>
<div>
      <b-skeleton-table v-if="loading" :columns="2" :rows="8"></b-skeleton-table>
      <b-container v-else :key="ticks">
            <b-row class="mb-1">
                  <b-col cols="12" md="4" class="font-weight-bold">Fråga</b-col>
                  <b-col cols="12" md="4">
                        <b-form-textarea v-model="question.question" placeholder="Skriv din fråga..."></b-form-textarea>
                  </b-col>
                  <b-col cols="12" md="4" class="font-italic font-small">
                        Frågan
                  </b-col>
                  <b-col cols="12"><hr/></b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col cols="6" md="4" class="font-weight-bold">Typ av fråga</b-col>
                  <b-col cols="6" md="4">
                        <b-select v-model="question.type">
                              <b-select-option value="1">Ge svar</b-select-option>
                              <b-select-option value="2">Alternativ</b-select-option>
                        </b-select>
                  </b-col>
                  <b-col cols="12" md="4" class="font-italic font-small">
                        1 = svara i text, 2 = droplista
                  </b-col>
                  <b-col cols="12"><hr/></b-col>
            </b-row>
            <b-row class="mb-1" v-if="question.type == '2'">
                  <b-col cols="6" md="4" class="font-weight-bold">Alternativ</b-col>
                  <b-col cols="6" md="4">
                        <b-input v-model="entry" placeholder="Ange alternativ"></b-input>
                  </b-col>
                  <b-col cols="12" md="4" class="font-italic font-small">
                        (ALLTEAM | GROUP | val1,val2,...,..,valn )
                  </b-col><b-col cols="12"><hr/></b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col cols="6" md="4" class="font-weight-bold">Poäng</b-col>
                  <b-col cols="6" md="4">
                        <b-select v-model="question.points">
                              <b-select-option v-for="i in 10" :key="i" :value="i">
                                    {{i}}
                              </b-select-option>
                        </b-select>
                  </b-col>
                  <b-col cols="12" md="4" class="font-italic font-small">
                        För rätt svar
                  </b-col><b-col cols="12"><hr/></b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col cols="6" md="4" class="font-weight-bold">Matchnummer</b-col>
                  <b-col cols="6" md="4">
                        <b-select v-model="gamenr">
                              <b-select-option value="">Direkt</b-select-option>
                              <b-select-option v-for="game in games" :key="game.id" :value="game.gamenr">{{game.gamenr}}, {{gameTextRow(game)}}</b-select-option>
                        </b-select>
                  </b-col>
                  <b-col cols="12" md="4" class="font-italic font-small">
                        När poängen räknas? (null = direkt)
                  </b-col><b-col cols="12"><hr/></b-col>
            </b-row>

            <b-row class="mb-1">
                  <b-col cols="6" md="4" class="font-weight-bold">Grupp</b-col>
                  <b-col cols="6" md="4">
                        <b-select v-model="groupid">
                              <b-select-option value="">Ingen</b-select-option>
                              <b-select-option v-for="group in groups" :key="group.id" :value="group.id">{{group.name}}</b-select-option>
                        </b-select>
                  </b-col>
                  <b-col cols="12" md="4" class="font-italic font-small">
                        För att ex fylla lagen från en grupp
                  </b-col><b-col cols="12"><hr/></b-col>
            </b-row>

            <b-row class="mb-1">
                  <b-col cols="12" md="4" class="font-weight-bold">Svar</b-col>
                  <b-col cols="12" md="4">
                        <b-input v-model="answer" placeholder="Ange svar"></b-input>
                  </b-col>
                  <b-col cols="12" md="4" class="font-italic font-small">
                        (ans1,ans2,...,...,ansN)
                  </b-col><b-col cols="12"><hr/></b-col>
            </b-row>

            <b-row class="mt-2" v-if="haserror">
                  <b-col>
                        <Alert variant="danger" v-for="(err, index) in terrors" :key="index" >{{err}}</Alert>
                  </b-col>
            </b-row>

            <hr />

            <b-row class="mb-1 mt-2">
                  <b-col class="text-center">
                        <b-button class="mx-1" @click="Cancel()" variant="secondary">Avbryt</b-button>
                        <b-button class="mx-1" @click="AddQuestion()" variant="primary">Spara</b-button>
                  </b-col>
            </b-row>
      </b-container>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
export default {
      Name: "AddEditQuestion",
      props: {
            "question": Object,
            "groups": Array,
            "games": Array
      },
      components: {
            Alert
      },
      data() {
            return {
                  terrors: [],
                  ticks: 1,
            }
      },
      computed: {
            loading() {
                  return this.$store.getters["adminpost/questionloading"];
            },
            haserror() {
                  return this.$store.getters["adminpost/questionerror"];
            },
            entry: {
                  get: function () {
                        var entry = "";
                        for (var i = 0; i < this.question.options.length; i++) {
                              entry += this.question.options[i].entry + ",";
                        }
                        if (entry.length > 0) {
                              entry = entry.slice(0, -1);
                        }
                        return entry;
                  },
                  set: function (value) {
                        var vals = value.split(",");
                        this.question.options = [];
                        for (var i = 0; i < vals.length; i++) {
                              var entry = {
                                    id: "0",
                                    questionid: "0",
                                    type: "0",
                                    entry: vals[i]
                              };
                              this.question.options.push(entry);
                        }
                  }
            },
            answer: {
                  get: function () {
                        var entry = "";
                        for (var i = 0; i < this.question.answers.length; i++) {
                              entry += this.question.answers[i].entry + ",";
                        }
                        if (entry.length > 0) {
                              entry = entry.slice(0, -1);
                        }
                        return entry;
                  },
                  set: function (value) {
                        var vals = value.split(",");
                        this.question.answers = [];
                        for (var i = 0; i < vals.length; i++) {
                              var entry = {
                                    id: "0",
                                    questionid: "0",
                                    type: "1",
                                    entry: vals[i]
                              };
                              this.question.answers.push(entry);
                        }
                  }
            },
            groupid: {
                  get: function(){
                        var gid = this.question.groupid;
                        if(gid)
                              return gid;
                        return "";
                  },
                  set: function(value){
                        this.question.groupid = value;
                  }
            },
            gamenr: {
                  get: function(){
                        var gnr = this.question.gamenr;
                        if(gnr)
                              return gnr;
                        return "";
                  },
                  set: function(value){
                        this.question.gamenr = value;
                  }
            },
      },
      methods: {
            gameTextRow(game) {
                  var htext = "Ej bestämt";
                  var atext = "Ej bestämt";
                  if (game.hometeamid > 0) {
                        var ht = this.$functions.getTeam(game.hometeamid);
                        htext = ht.dispname;
                  }
                  if (game.awayteamid > 0) {
                        var at = this.$functions.getTeam(game.awayteamid);
                        atext = at.dispname;
                  }

                  return htext + " - " + atext;
            },
            AddQuestion() {
                  this.$store.dispatch("adminpost/AddQuestion", this.question).then((response) => {
                        if (response.status == "ok") {
                              this.$emit("saved");
                        } else {
                              this.terrors = response.errors;
                        }
                  });
            },
            Cancel() {
                  this.$emit("cancel");
            }
      },
};
</script>
